'use strict';

// node_modules

import 'regenerator-runtime/runtime'; // 24KB

import moment from 'moment'; // 143KB
window.moment = moment;

// jQuery for global
import $ from 'jquery';
window.jQuery = window.$ = $;

import 'angular';

import '@uirouter/angularjs';

import 'oclazyload';

import 'angular-animate';

import 'angular-sanitize';

import 'angular-messages';

import 'angular-aria';

import 'angular-material';

import 'angular-material/angular-material.css';

import 'angular-translate'; // 128KB

import 'angular-spinner'; // 20KB

// import 'ng-idle';

// modules for bootstrap accordion and datepicker
import 'angular-ui-bootstrap/src/accordion';
import 'angular-ui-bootstrap/src/datepicker';
import 'angular-ui-bootstrap/src/popover';
import 'angular-ui-bootstrap/src/tooltip';

import 'ng-file-upload'; // 94KB

import 'angular-local-storage'; // 22KB

// Import bootstrap custom scss.
import './third-party/bootstrap-custom.scss';

import 'offline-js/themes/offline-theme-default.css';
import 'offline-js/themes/offline-language-english.css';
import 'offline-js'; //54KB

import 'clientjs'; //46KB

import "fullcalendar"; //610KB
import "fullcalendar/dist/fullcalendar.css";
import "_libs/angular-ui-calendar/src/calendar";

import Driver from 'driver.js'; // 58KB
window.Driver = Driver;
import 'driver.js/dist/driver.min.css';

const imgCompression = require('compressorjs'); // 10KB
window.Compressor = imgCompression;